import React, { useState, useContext, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ProfileMain from "../../Pages/Profile/ProfileMain";
import { AuthContext } from "../../Context/AuthContext";
import {
  ShowImage,
  showLoader,
  hideLoader,
  haveValue,
} from "../../utils/helpers";
import AuthAction from "../../Actions/auth.service";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";

function HomeHeader({
  allEvents,
  setAllEvents,
  selectedEventListing,
  setSelectedEventListing,
}) {
  const { isLoggedIn, isSidebarVisible, setIsSidebarVisible, setIsLoggedIn } =
    useContext(AuthContext);

  const [myUserInfo, setMyUserInfo] = useState([]);
  const [isHomePage, setIsHomePage] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const navigateEditProfile = () => {
    navigate("/editprofile");
  };

  useEffect(() => {
    if (
      haveValue(myUserInfo?._id) &&
      (!haveValue(myUserInfo?.anniversary) || !haveValue(myUserInfo?.dob))
    ) {
      setShowModal(true);
    }
  }, [myUserInfo]);

  useEffect(() => {
    showLoader();
    setIsSidebarVisible(false);
    console.log(location.pathname, "location.pathname");
    if (location.pathname == "/getStarted") {
      setIsHomePage(true);
    }
    if (isLoggedIn) {
      AuthAction.getUserData()
        .then((res) => {
          setMyUserInfo(res);
          hideLoader();
        })
        .catch((err) => {
          console.log(err);
          if (err?.response?.data?.message == "Unauthorized User.") {
            localStorage.removeItem("token");
            setIsLoggedIn(false);
            setIsSidebarVisible(false);

            navigate("/");
          }
        });
    }
  }, [isLoggedIn]);

  const handleChangeEventListing = (eventListingType) => {
    setSelectedEventListing(eventListingType);
    AuthAction.getEventData(eventListingType)
      .then((res) => {
        setAllEvents(res);
        hideLoader();
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.message == "Unauthorized User.") {
          localStorage.removeItem("token");
          toast.dismiss();
          setIsLoggedIn(false);
          setIsSidebarVisible(false);
          navigate("/");
        }
        hideLoader();
      });
  };

  return (
    <>
      <ul>
        <li className="logobar">
          <Link to="/" className="brand">
            <img src="/images/wegroup-white.svg" alt="whitelogo" />
            <h6 className="mb-0">BETA</h6>
          </Link>
          <p>Group Gifting made easy</p>
        </li>
        <li className="userbar">
          {isLoggedIn ? (
            <>
              <button onClick={(e) => navigate("/notifications")}>
                <img
                  className=""
                  src="/images/NotificationIcon.svg"
                  alt="img"
                />
              </button>
              <button
                onClick={() => {
                  setIsSidebarVisible(true);
                }}
              >
                <img
                  className="profimnew"
                  src={ShowImage(
                    myUserInfo?.profilePhoto,
                    myUserInfo?.fullName
                  )}
                  alt="img"
                />
              </button>
            </>
          ) : (
            ""
          )}

          <div className={`sidebar ${isSidebarVisible ? "active" : "hidden"}`}>
            <div className="sidebarin">
              <button
                className="closesidebar"
                onClick={() => {
                  setIsSidebarVisible(false);
                }}
              >
                <img src="/images/cross.png" alt="crossicon" />
              </button>

              <ProfileMain />
            </div>
          </div>
        </li>
      </ul>
      {isHomePage && isLoggedIn && (
        <>
          <div className="event_lists_div">
            <ul className="event_lists">
              <li
                className={`${
                  selectedEventListing == "all_events" && "selected"
                }`}
                onClick={(e) => handleChangeEventListing("all_events")}
              >
                All events
              </li>
              <li
                className={`${
                  selectedEventListing == "contributed_in" && "selected"
                }`}
                onClick={(e) => handleChangeEventListing("contributed_in")}
              >
                Contributed In
              </li>
              <li
                className={`${selectedEventListing == "created" && "selected"}`}
                onClick={(e) => handleChangeEventListing("created")}
              >
                Created
              </li>
              <li
                className={`${selectedEventListing == "for_you" && "selected"}`}
                onClick={(e) => handleChangeEventListing("for_you")}
              >
                For You
              </li>
            </ul>
          </div>

          <Modal
            className="Createevent-modal fixamountpopup dateadded-popup"
            show={showModal}
            onHide={handleCloseModal}
          >
            <Modal.Header className="faviconadded" closeButton>
              Update birth and anniversary date
            </Modal.Header>
            <Modal.Body className="text-center">
              <img src="/images/DateIcon.svg" />
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={navigateEditProfile}
                className="btn-pink"
              >
                Update Now
              </button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
}

export default HomeHeader;
