import React, { useState, useContext, useEffect } from "react";
import Button from "react-bootstrap/esm/Button";
import { Link, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import PhoneInput from "react-phone-input-2";
import eventAction from "../../Actions/event.service";
import { CreateContext, MyContext } from "../../Context/AuthContext";
import copy from "copy-to-clipboard";
import * as Joi from "joi-browser";
import FieldError from "./../../../src/Components/Common/FieldError";
import {
  validateJOIFormField,
  validateJOIProperty,
  hideLoader,
  showLoader,
  toTitleCase,
  getTotalContri,
  isNotEmpty,
  haveValue,
} from "./../../utils/helpers";
import EventAction from "./../../Actions/event.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import ParticipantRow from "../../Components/Events/ParticipantRow";
import { AuthContext } from "../../Context/AuthContext";
import { ThemeContext } from "../../hooks/useThemeContext";
import { BsFillShareFill } from "react-icons/bs";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AddFromConnectionModal from "../../Components/ConnectionCard/AddFromConnectionModal";

function ParticipantList(props) {
  const { isLoggedInUserId, loggedInUserId } = useContext(AuthContext);

  const { id } = useParams();
  const [data, setData] = useState({});
  const [alertShow, setAlertShow] = useState(false);
  const [show, setAddParticipant] = useState(false);
  const [whtsapp, setWhtsapp] = useState(false);
  const [showConnectionsModal, setShowConnectionsModal] = useState(false);

  const AddParticipantShow = () => {
    setAddParticipant(true);
    setInvitepeople(false);
  };

  // Invite People
  const [Inviteshow, setInvitepeople] = useState(false);
  const InvitePeopleClose = () => setInvitepeople(false);
  const InvitepeopleShow = () => setInvitepeople(true);
  // Revoke
  const [RevokeShowclick, RevokeShow] = useState(false);
  const [showReminder, setShowReminder] = useState(false);
  const RevokeClose = () => RevokeShow(false);
  const RevokeShowbar = () => RevokeShow(true);
  // Add Another Modal
  const [showanother, setAddAnother] = useState(false);
  const AddAnotherClose = () => setAddAnother(false);
  const AddAnotherShow = () => setAddAnother(true);
  const [countryCode, setCountryCode] = useState("us");
  const payload = { createdBy: data.createdBy, eventId: data._id };
  const [errors, setErrors] = useState({});
  const [copyText, setCopyText] = useState("");
  const [participants, setParticipants] = useState([]);
  const [joinedPart, setJoinedPart] = useState([]);

  let inititalFormData = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
  };
  const [formData, setFormData] = useState(inititalFormData);

  const {
    showErrorToast,
    osName,
    isMobile,
    setBackUrlContext,
    showSuccessToast,
  } = useContext(ThemeContext);

  const copyToClipboard = () => {
    copy(copyText);
  };

  const AddParticipantClose = () => {
    setAddParticipant(false);
  };

  const addAnotherManual = () => {
    setAddAnother(false);
    setFormData(inititalFormData);
    setWhtsapp(false);
    setAddParticipant(true);
  };

  const handleClosedateadded = () => {
    setAlertShow(false);
  };

  const showRecheckAlert = () => {
    let errors = validateJOIFormField(formData, schema);
    if (errors == null) {
      setAlertShow(true);
    } else {
      setErrors(errors);
    }
  };

  const getLocation = async () => {
    axios({ url: `https://ipapi.co/json/` })
      .then((res) => {
        setCountryCode(res?.data?.country_code.toString().toLowerCase());
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getLocation();
  }, [participants]);

  useEffect(() => {
    participantsList();
    setBackUrlContext(`/${localStorage.getItem("role") ?? "organiser"}/${id}`);
  }, []);

  useEffect(() => {
    EventAction.viewEvent(id)
      .then((res) => {
        setData(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const participantsList = () => {
    showLoader();
    eventAction
      .participantsList(id)
      .then((res) => {
        hideLoader();
        setParticipants(res);
        let resultA = [];
        if (isNotEmpty(res)) {
          const newFilter = Object.keys(res).reduce((result, key) => {
            if (res[key].status.includes("joined")) {
              resultA.push(res[key]);
            }
            setJoinedPart(resultA);
          }, []);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSendInvitation = () => {
    let errors = validateJOIFormField(formData, schema);
    if (errors == null) {
      showLoader();
      payload["name"] = formData?.firstName + " " + formData?.lastName;
      payload["email"] = formData.email;
      payload["phoneNumber"] = `+${formData.phoneNumber}`;
      eventAction
        .invite(payload)
        .then((res) => {
          setAddParticipant(false);
          setAlertShow(false);
          AddAnotherShow();
          participantsList();
          setFormData(inititalFormData);
          setWhtsapp(false);
          hideLoader();
        })
        .catch((err) => {
          hideLoader();
          console.log(err);
          handleClosedateadded();
          showErrorToast(err.message);
        });
    } else {
      setErrors(errors);
    }
  };

  const handleInviteLink = (type = null) => {
    showLoader();
    eventAction.invite(payload).then(async (res) => {
      try {
        const text = `Let's unleash the potential of togetherness! Get ready for an unforgettable celebration because this year we're taking ${
          data?.name
        }'s ${
          data?.occasionId
        } to a whole new level! \nJoin us on 'weGroup' to create a gift that will make a lasting impact for ${
          data?.name
        }'s ${
          data?.occasionId
        }. \n\nAct now: ${res} and spark this transformative journey. \n\nContribution: ${getTotalContri(
          data?.contriPerPerson
        )} \n\nTogether, we'll create a memorable legacy! \n\nWarmest regards, \n\n(${toTitleCase(
          data?.createdBy?.fullName
        )})`;
        hideLoader();
        if (type == null) {
          await window.navigator.share({ text });
        } else if (type == "contacts") {
          const contacts = await navigator.contacts.select(["name", "tel"], {
            multiple: true,
          });
          const contactNums = [];
          contacts.forEach((contact) => {
            if (contact.tel) {
              let telNo = contact.tel;
              contactNums.push(telNo);
            }
          });
          if (contactNums.length > 0) {
            let numbersConcatted = contactNums.join(",");
            let urlString = `sms:${numbersConcatted}?body=${encodeURIComponent(
              text
            )}`;
            // console.log(urlString);
            window.location.href = urlString;
          } else {
            showErrorToast("No Contacts Found");
          }
        }
      } catch (err) {
        console.log(err);
        hideLoader();
        let message = err.message.toString().toLowerCase();
        if (message.includes("cancel")) {
          //nothing
        } else {
          window.prompt("Copy Invite Link", res);
          console.log(err);
        }
      }
    });
  };

  const schema = {
    firstName: Joi.string().required().label("First Name"),
    lastName: Joi.string().required().label("Last Name"),
    phoneNumber: Joi.string().label("Phone Number").min(10),
    email: Joi.string().email().optional().allow("").label("Email"),
  };

  const handleForm = (e, name) => {
    console.log(e);
    let fieldName = name;
    var fieldValue = e;
    if (name == "phoneNumber") {
      let temp_number_string = e.toString();
      let lastTenDigits = temp_number_string;
      // let lastTenDigits = temp_number_string.slice(-12);
      console.log(typeof lastTenDigits, lastTenDigits);
      fieldValue = lastTenDigits;
    }
    let errorData = { ...errors };
    const errorMessage = validateJOIProperty(schema, fieldName, fieldValue);
    if (errorMessage) {
      errorData[fieldName] = errorMessage;
    } else {
      delete errorData[fieldName];
    }
    // console.log(fieldName, fieldValue)
    setErrors(errorData);
    setFormData((existingValue) => ({
      ...existingValue,
      [fieldName]: fieldValue,
    }));
  };

  const handleUpdate = () => {
    participantsList();
  };

  const handleConfirmReminder = async () => {
    setShowReminder(true);
  };
  const handleCancelReminder = async () => {
    setShowReminder(false);
  };

  const handleSendReminder = async () => {
    showLoader();
    setShowReminder(false);
    eventAction
      .sendAllReminder(id)
      .then(async (res) => {
        try {
          hideLoader();
          showSuccessToast("Reminders sent");
          console.log(res);
        } catch (err) {
          hideLoader();
        }
      })
      .catch((err) => {
        console.log(err);
        hideLoader();
      });
  };

  const handleCloseConnectionsModal = () => {
    setShowConnectionsModal(false);
  };

  const handleShowConnectionModal = () => {
    setShowConnectionsModal(true);
    setInvitepeople(false);
  };

  return (
    <>
      <h4 className="titleevent participant_list_header">
        Participants
        {isLoggedInUserId(data?.createdBy?._id) && (
          <div className="viewbar">
            <Link
              to={undefined}
              onClick={handleConfirmReminder}
              className="btn-pink p-3 PxHeight30"
            >
              Send Reminder
            </Link>
          </div>
        )}
      </h4>
      <div className="Participant-Listall participantheight-auto">
        <ul className="participant-list-ul">
          {participants.map((item) => {
            return (
              <>
                <ParticipantRow
                  onUpdate={handleUpdate}
                  event={data}
                  participant={item}
                  key={item._id}
                />
              </>
            );
          })}
        </ul>
        {haveValue(joinedPart) && data?.maxParticipants > joinedPart?.length ? (
          data && !data?.isConfirmed ? (
            isLoggedInUserId(data?.createdBy?._id) ? (
              <>
                <div className="savebtn">
                  <Link className="btn-pink" onClick={InvitepeopleShow}>
                    <svg
                      width="19"
                      height="18"
                      viewBox="0 0 19 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.3346 16.5V11.5M12.8346 14H17.8346M9.5013 11.5H6.16797C4.61483 11.5 3.83826 11.5 3.22569 11.7537C2.40893 12.092 1.76002 12.741 1.4217 13.5577C1.16797 14.1703 1.16797 14.9469 1.16797 16.5M12.418 1.7423C13.6396 2.23679 14.5013 3.43443 14.5013 4.83333C14.5013 6.23224 13.6396 7.42988 12.418 7.92437M10.7513 4.83333C10.7513 6.67428 9.25892 8.16667 7.41797 8.16667C5.57702 8.16667 4.08464 6.67428 4.08464 4.83333C4.08464 2.99238 5.57702 1.5 7.41797 1.5C9.25892 1.5 10.7513 2.99238 10.7513 4.83333Z"
                        stroke="white"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Add / Invite people
                  </Link>
                </div>
              </>
            ) : data?.participantCanInvite ? (
              <>
                <div className="savebtn">
                  <Link className="btn-pink" onClick={InvitepeopleShow}>
                    <svg
                      width="19"
                      height="18"
                      viewBox="0 0 19 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.3346 16.5V11.5M12.8346 14H17.8346M9.5013 11.5H6.16797C4.61483 11.5 3.83826 11.5 3.22569 11.7537C2.40893 12.092 1.76002 12.741 1.4217 13.5577C1.16797 14.1703 1.16797 14.9469 1.16797 16.5M12.418 1.7423C13.6396 2.23679 14.5013 3.43443 14.5013 4.83333C14.5013 6.23224 13.6396 7.42988 12.418 7.92437M10.7513 4.83333C10.7513 6.67428 9.25892 8.16667 7.41797 8.16667C5.57702 8.16667 4.08464 6.67428 4.08464 4.83333C4.08464 2.99238 5.57702 1.5 7.41797 1.5C9.25892 1.5 10.7513 2.99238 10.7513 4.83333Z"
                        stroke="white"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Add / Invite people
                  </Link>
                </div>
              </>
            ) : (
              ""
            )
          ) : (
            ""
          )
        ) : (
          <p>Maximum Participants Limit Reached </p>
        )}
      </div>

      {/* InvitePeople Modal */}
      <Modal
        className="Createevent-modal InvitePeople-modal"
        show={Inviteshow}
        onHide={InvitePeopleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Invite People</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="invitebar">
            {data?.participantCanInvite ? (
              <>
                <div className="invitebar-in" style={{ cursor: "pointer" }}>
                  <div className="labelbar">Faster</div>
                  <div className="invite-icon">
                    <BsFillShareFill />
                  </div>
                  <div
                    className="invitecontent"
                    onClick={(e) => handleInviteLink()}
                  >
                    <h5>Invite via Link</h5>
                    <p>Share with you group so all can join</p>
                    <ul className="social-bar">
                      <li>
                        <img src="/images/whatsapp.png" alt="whatsapp" />
                      </li>
                      <li>
                        <img src="/images/gmail.png" alt="whatsapp" />
                      </li>
                      <li>
                        <img src="/images/Facebook.png" alt="whatsapp" />
                      </li>
                    </ul>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
            {osName.toString().toLowerCase() === "android" &&
            isMobile === true ? (
              <>
                <div
                  className="invitebar-in"
                  onClick={(e) => handleInviteLink("contacts")}
                >
                  <div className="invite-icon">
                    <img src="/images/files.png" alt="icon" />
                  </div>
                  <div className="invitecontent">
                    <h5>Choose from your contacts</h5>
                    <p>You will be asked to allow contacts access</p>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            <div className="invitebar-in" onClick={AddParticipantShow}>
              <div className="invite-icon">
                <img src="/images/users.png" alt="icon" />
              </div>
              <div className="invitecontent">
                <h5>Add Manually</h5>
              </div>
            </div>
            <div className="invitebar-in" onClick={handleShowConnectionModal}>
              <div className="invite-icon">
                <img src="/images/ConnectionsLogo.svg" alt="icon" />
              </div>
              <div className="invitecontent">
                <h5>Add From Connections</h5>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Add Participant Manual */}
      <Modal
        className="Createevent-modal"
        show={show}
        onHide={AddParticipantClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Participant Manually</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Container>
              <Row className="margin-minus">
                <Col>
                  <Form.Group className="formgroup" controlId="formBasicEmail">
                    <Form.Label>First Name of the Invitee</Form.Label>
                    <div className="usericon">
                      <svg
                        width="18"
                        height="17"
                        viewBox="0 0 18 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.5 15.6667C3.44649 13.6021 6.08918 12.3333 9 12.3333C11.9108 12.3333 14.5535 13.6021 16.5 15.6667M12.75 5.25C12.75 7.32107 11.0711 9 9 9C6.92893 9 5.25 7.32107 5.25 5.25C5.25 3.17893 6.92893 1.5 9 1.5C11.0711 1.5 12.75 3.17893 12.75 5.25Z"
                          stroke="#667085"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>

                      <Form.Control
                        className={errors?.firstName ? "error" : ""}
                        type="text"
                        name="firstName"
                        placeholder="Enter Here"
                        value={formData?.firstName}
                        onChange={(e) => {
                          const regex = /^[a-zA-Z0-9 ]*$/;
                          const inputValue = e.target.value;
                          if (regex.test(inputValue)) {
                            handleForm(e?.target.value, "firstName");
                          }
                        }}
                      />
                    </div>
                    <FieldError error={errors.firstName} />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="formgroup" controlId="formBasicEmail">
                    <Form.Label>Last Name of the Invitee</Form.Label>
                    <div className="usericon">
                      <svg
                        width="18"
                        height="17"
                        viewBox="0 0 18 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.5 15.6667C3.44649 13.6021 6.08918 12.3333 9 12.3333C11.9108 12.3333 14.5535 13.6021 16.5 15.6667M12.75 5.25C12.75 7.32107 11.0711 9 9 9C6.92893 9 5.25 7.32107 5.25 5.25C5.25 3.17893 6.92893 1.5 9 1.5C11.0711 1.5 12.75 3.17893 12.75 5.25Z"
                          stroke="#667085"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>

                      <Form.Control
                        className={errors?.lastName ? "error" : ""}
                        type="text"
                        name="lastName"
                        placeholder="Enter Here"
                        value={formData?.lastName}
                        onChange={(e) => {
                          const regex = /^[a-zA-Z0-9 ]*$/;
                          const inputValue = e.target.value;
                          if (regex.test(inputValue)) {
                            handleForm(e?.target.value, "lastName");
                          }
                        }}
                      />
                    </div>
                    <FieldError error={errors.lastName} />
                  </Form.Group>
                </Col>
              </Row>
            </Container>
            <Form.Group className="formgroup" controlId="formBasicEmail">
              <Form.Label>Mobile Number</Form.Label>
              {/* <Form.Control maxlength="10" type="number" placeholder="Enter your mobile number" /> */}
              <PhoneInput
                enableLongNumbers={true}
                countryCodeEditable={false}
                className={errors.phoneNumber ? "error" : ""}
                type="text"
                name="phoneNumber"
                country={countryCode}
                value={formData?.phoneNumber}
                placeholder="Enter your mobile number"
                onChange={(phone) => handleForm(phone, "phoneNumber")}
              />
            </Form.Group>
            <FieldError error={errors.phoneNumber} />
            <Form.Group className="formgroup" controlId="formBasicEmail">
              <Form.Label>Participant Email</Form.Label>
              <Form.Control
                className={errors.email ? "error" : ""}
                type="text"
                name="email"
                placeholder="Enter Invitee email (Optional)"
                value={formData?.email}
                onChange={(e) => handleForm(e?.target.value, "email")}
              />
            </Form.Group>
            <FieldError error={errors.email} />
            {/* <div className='shareonwtsapp'>
              <Form.Check type="checkbox" className="checkbox" name='whatsapp_update' onChange={e => setWhtsapp(oldstate => !oldstate)} checked={whtsapp} />
              <p>Share updates on WhatsApp</p>
              <Button className='wtsapp'><img src='/images/wtsapp.png' alt='wtsappimg' /></Button>
            </div> */}
            <div className="btnbar">
              <Button className="btn-pink" onClick={showRecheckAlert}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.8346 6.66663L18.3346 4.16663M18.3346 4.16663L15.8346 1.66663M18.3346 4.16663L13.3346 4.16663M18.3346 9.99996V14.3333C18.3346 15.7334 18.3346 16.4335 18.0622 16.9683C17.8225 17.4387 17.44 17.8211 16.9696 18.0608C16.4348 18.3333 15.7348 18.3333 14.3346 18.3333H5.66797C4.26784 18.3333 3.56777 18.3333 3.03299 18.0608C2.56259 17.8211 2.18014 17.4387 1.94045 16.9683C1.66797 16.4335 1.66797 15.7334 1.66797 14.3333V5.66663C1.66797 4.26649 1.66797 3.56643 1.94045 3.03165C2.18014 2.56124 2.56259 2.17879 3.03299 1.93911C3.56777 1.66663 4.26784 1.66663 5.66797 1.66663H10.0013M1.78923 16.6052C2.18018 15.1988 3.47011 14.1666 5.00111 14.1666H10.8344C11.6089 14.1666 11.9961 14.1666 12.3181 14.2307C13.6404 14.4937 14.674 15.5274 14.9371 16.8497C15.0011 17.1717 15.0011 17.5589 15.0011 18.3333M11.668 7.91663C11.668 9.75758 10.1756 11.25 8.33464 11.25C6.49369 11.25 5.0013 9.75758 5.0013 7.91663C5.0013 6.07568 6.49369 4.58329 8.33464 4.58329C10.1756 4.58329 11.668 6.07568 11.668 7.91663Z"
                    stroke="white"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Send Invitation
              </Button>
              <p>
                Participant will receive an invitation link on Email & Whatsapp
                from which they will be able to contribute to this event.
              </p>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* Add another modal */}
      <Modal
        className="Createevent-modal addanother-modal"
        show={showanother}
        onHide={AddAnotherClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Participant Manually</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src="/images/success.png" alt="img" />
          <h4>Invite has been Sent</h4>
          <div className="btnbar">
            <Button onClick={addAnotherManual} className="btn-pink">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.8346 6.66663L18.3346 4.16663M18.3346 4.16663L15.8346 1.66663M18.3346 4.16663L13.3346 4.16663M18.3346 9.99996V14.3333C18.3346 15.7334 18.3346 16.4335 18.0622 16.9683C17.8225 17.4387 17.44 17.8211 16.9696 18.0608C16.4348 18.3333 15.7348 18.3333 14.3346 18.3333H5.66797C4.26784 18.3333 3.56777 18.3333 3.03299 18.0608C2.56259 17.8211 2.18014 17.4387 1.94045 16.9683C1.66797 16.4335 1.66797 15.7334 1.66797 14.3333V5.66663C1.66797 4.26649 1.66797 3.56643 1.94045 3.03165C2.18014 2.56124 2.56259 2.17879 3.03299 1.93911C3.56777 1.66663 4.26784 1.66663 5.66797 1.66663H10.0013M1.78923 16.6052C2.18018 15.1988 3.47011 14.1666 5.00111 14.1666H10.8344C11.6089 14.1666 11.9961 14.1666 12.3181 14.2307C13.6404 14.4937 14.674 15.5274 14.9371 16.8497C15.0011 17.1717 15.0011 17.5589 15.0011 18.3333M11.668 7.91663C11.668 9.75758 10.1756 11.25 8.33464 11.25C6.49369 11.25 5.0013 9.75758 5.0013 7.91663C5.0013 6.07568 6.49369 4.58329 8.33464 4.58329C10.1756 4.58329 11.668 6.07568 11.668 7.91663Z"
                  stroke="white"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Add Another Manually
            </Button>
            <Button onClick={AddAnotherClose} className="btngray">
              Close
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="Createevent-modal fixamountpopup dateadded-popup"
        show={alertShow}
        onHide={handleClosedateadded}
      >
        <Modal.Header className="faviconadded" closeButton>
          <img src="./../../../images/favicon.png" />
          weGroup
        </Modal.Header>
        <Modal.Body>Confirm if the details are correct?</Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            onClick={handleSendInvitation}
            className="btn-pink"
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="Createevent-modal fixamountpopup dateadded-popup"
        show={showReminder}
        onHide={handleCancelReminder}
      >
        <Modal.Header className="faviconadded" closeButton>
          <img src="./../../../images/favicon.png" />
          weGroup
        </Modal.Header>
        <Modal.Body>
          We will send reminder to all the participants based on their current
          participation stage.
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            onClick={handleSendReminder}
            className="btn-pink"
          >
            Send
          </Button>
          <Button
            type="button"
            onClick={handleCancelReminder}
            className="btngray"
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <AddFromConnectionModal
        showModal={showConnectionsModal}
        handleCloseModal={handleCloseConnectionsModal}
        createdBy={data.createdBy}
        eventId={data._id}
      />
    </>
  );
}

export default ParticipantList;
