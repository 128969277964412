import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import AuthAction from "../Actions/auth.service";
import EventAction from "../Actions/event.service";
import { useNavigate, useLocation } from "react-router-dom";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../firbase";
import axios from "axios";
import { useGoogleLogin } from "@react-oauth/google";
import { AuthContext } from "../Context/AuthContext";
import { useContext } from "react";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import OtpInput from "react-otp-input";
import { ThemeContext } from "../hooks/useThemeContext";
import { haveValue, showLoader, hideLoader, isEmpty } from "../utils/helpers";
import Moment from "moment";
import config from "../Config/Config";

const Login = (props) => {
  //OTP
  const [userData, setUserData] = useState();
  const [isError, setIsError] = useState(false);
  const [isphoneEmpty, setIsphoneEmpty] = useState(false);
  let cookie = new Cookies();
  const navigate = useNavigate();
  let recaptchaWrapperRef;
  const [Terms, setTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingOtp, setLoadingOtp] = useState(false);
  const [user, setUser] = useState();
  const { setIsLoggedIn, setFirebaseIdToken, eventData, setEventData } =
    useContext(AuthContext);
  const [otp, setOtp] = useState("");
  // const [minutes, setMinutes] = useState(1);
  const [times, setTimes] = useState(3);
  const [seconds, setSeconds] = useState();
  const [googleToken, setGoogleToken] = useState("");
  const [viewTerms, setViewTerms] = useState(false);
  const [inCorrect, setIncorrect] = useState(false);
  const { state } = useLocation();
  // State variables

  const [phoneNumber, setPhoneNumber] = useState("");
  // const [countryPrefix, setCountryPrefix] = useState("1");
  const [otpScreen, setOtpScreen] = useState(false);
  const [countryCode, setCountryCode] = useState("us");
  const { showErrorToast, setBackUrlContext, hide, setHide } =
    useContext(ThemeContext);
  const { detect } = require("detect-browser");
  const browser = detect();

  const GoogleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      setGoogleToken(tokenResponse);
    },
  });
  useEffect(() => {
    getLocation();
    if (
      localStorage.getItem("link") &&
      (localStorage.getItem("link").startsWith("/allgiftcard") ||
        localStorage.getItem("backUrl"))
    ) {
      setBackUrlContext(localStorage.getItem("backUrl"));
    } else {
      setBackUrlContext(`home`);
    }
    console.log(browser.name, "browser.name", browser);
  }, []);

  useEffect(() => {
    if (googleToken != "") {
      googleLoginApi();
    }
  }, [googleToken]);

  const viewTermsPage = () => {
    setViewTerms(true);
    setHide(true);
  };
  const hideTermsPage = () => {
    setViewTerms(false);
    setHide(false);
  };

  const googleLoginApi = async () => {
    AuthAction.verifyGoogleSignUp(googleToken)
      .then((res) => {
        if (res.newUserCreated) {
          localStorage.setItem("googleData", JSON.stringify(res.googleData));
          navigate("/infofill");
        } else {
          const token = res.token;
          localStorage.setItem("token", token);
          setIsLoggedIn(true);
          addEvent();
          if (localStorage.getItem("link")) {
            const link = localStorage.getItem("link");
            navigate(`${link}`);
          } else {
            navigate("/getStarted");
          }
        }

        // const token = res.token;
        // localStorage.setItem("token", token);
        // setIsLoggedIn(true);
        // if (localStorage.getItem("link")) {
        //   const link = localStorage.getItem("link");
        //   navigate(`${link}`);
        // } else {
        //   navigate("/home");
        // }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const getLocation = async () => {
    axios({ url: `https://ipapi.co/json/` })
      .then((res) => {
        setCountryCode(res?.data?.country_code.toString().toLowerCase());
      })
      .catch((err) => {});
  };
  const submitHandler = async () => {
    if (recaptchaWrapperRef) {
      recaptchaWrapperRef.innerHTML = `<div id="recaptcha-container"></div>`;
    }
    if (isEmpty(phoneNumber)) {
      setIsphoneEmpty(true);
      return;
    } else {
      setIsphoneEmpty(false);
    }
    // if (phoneNumber.length < 10) {
    //   alert("Phone Number Invalid");
    //   return false;
    // }
    setLoading(true);

    const number = "+" + phoneNumber;
    if (config.DEMO == "true") {
      setLoading(false);
      setIsError(false);
      setOtpScreen(true);
      setSeconds(60);
    } else {
      console.log("submitHandler in false");
      const recaptchaVerifier = await new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
        },
        auth
      );
      let result = {};
      console.log(number, "check this");
      signInWithPhoneNumber(auth, number, recaptchaVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          result = confirmationResult;
          let dataToSend = {
            phoneNumber: "+" + phoneNumber,
          };
          setUserData(result);
          setLoading(false);
          document.getElementById("hellllll").innerHTML =
            "<div id='recaptcha'></div>";
          recaptchaVerifier.clear();
          setIsError(false);
          // navigate("/enterotp");
          setOtpScreen(true);
          setSeconds(60);
          // navigate(to = "/enterotp" state = {{ phoneNumber }});
        })
        .catch((error) => {
          setLoading(false);
          // alert("Auth/invalid-phone-number");
          document.getElementById("hellllll").innerHTML =
            "<div id='recaptcha'></div>";
          recaptchaVerifier.clear();
          setIsError(true);
          // toast("Failed to send OTP. Please check your mobile number.");
          console.log(error, error.message);
        });
    }
  };

  //OTP FN
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        // if (minutes === 0) {
        clearInterval(interval);
        // } else {
        // setSeconds(59);
        // setMinutes(minutes - 1);
        // }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const submitOtp = async () => {
    setLoadingOtp(true);
    if (Terms == false) {
      showErrorToast("Please Agree to terms and conditions");
      setLoadingOtp(false);
      return false;
    }

    if (otp === "" || otp === null) {
      setIncorrect(true);
      setLoadingOtp(false);
      return false;
    }

    if (config.DEMO == "true") {
      try {
        setLoadingOtp(false);
        setOtp("");
        const demofirebaseIdToken = { phoneNumber: `+${phoneNumber}` };
        setFirebaseIdToken(demofirebaseIdToken);
        AuthAction.loginSignup({ demofirebaseIdToken })
          .then((res) => {
            setIncorrect(false);
            if (res.newUserCreated) {
              navigate("/infofill");
            } else {
              const token = res.token;
              localStorage.setItem("token", token);
              setIsLoggedIn(true);
              addEvent();
              if (localStorage.getItem("link")) {
                const link = localStorage.getItem("link");
                navigate(`${link}`);
              } else {
                navigate("/getStarted");
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (err) {
        setIncorrect(true);
        setLoadingOtp(false);
      }
    } else {
      try {
        let data = await userData.confirm(otp);
        const firebaseIdToken = data._tokenResponse;
        cookie.set("x-access-token-gt", data._tokenResponse.idToken);
        setUser(data._tokenResponse.idToken);
        setLoadingOtp(false);
        setOtp("");
        setFirebaseIdToken(firebaseIdToken);
        AuthAction.loginSignup({ firebaseIdToken })
          .then((res) => {
            setIncorrect(false);
            if (res.newUserCreated) {
              navigate("/infofill");
            } else {
              const token = res.token;
              localStorage.setItem("token", token);
              setIsLoggedIn(true);
              addEvent();
              if (localStorage.getItem("link")) {
                const link = localStorage.getItem("link");
                navigate(`${link}`);
              } else {
                navigate("/getStarted");
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (err) {
        // toast("Incorrect OTP", err);
        setIncorrect(true);
        setLoadingOtp(false);
      }
    }
  };
  const resendOtp = async () => {
    setTimes(times - 1);
    setSeconds(60);
    submitHandler();
  };

  const addEvent = async () => {
    if (localStorage.getItem("byPassLogin") && haveValue(eventData)) {
      const selectedDate = new Date(
        eventData?.date.concat(", ", eventData?.time)
      );
      const utcTime = new Date(
        selectedDate.getTime() + selectedDate.getTimezoneOffset() * 60000
      );
      const data = new FormData();
      data.append("name", eventData?.firstName + " " + eventData?.lastName);
      data.append("eventDescription", eventData?.eventDescription);
      data.append("email", eventData?.email);
      data.append("occasionId", eventData?.occasionId);
      // data.append("eventStart", Moment(eventData?.date).format("LL"));
      // data.append("eventTime", eventData?.time);
      data.append("eventStart", Moment(utcTime).format("LL"));
      data.append("eventTime", Moment(utcTime).format("h:mm A"));
      data.append("phone", eventData?.phone);
      data.append("isSolo", eventData?.isSolo);

      if (eventData?.icon) {
        data.append("file", eventData?.icon);
      }
      showLoader();
      EventAction.createEvent(data)
        .then((res) => {
          localStorage.removeItem("byPassLogin");
          setEventData(res);
          hideLoader();
          navigate("/eventSuccess");
        })
        .catch((err) => {
          showErrorToast("Failed to create Event");
          hideLoader();
          console.log(err);
        });
    }
  };

  return (
    <>
      {viewTerms ? (
        <>
          <div className="terms max-width-window">
            <div class="Headerbar">
              <ul>
                <li>
                  <button
                    className="backtobasic simplebtn"
                    onClick={(e) => hideTermsPage()}
                  >
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                      height="1em"
                      width="1em"
                      xmlns="http:/ / www.w3.org / 2000 / svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </li>
                <li></li>
              </ul>
            </div>
            <div className="body-wrapper">
              <h2 className="titleevent">Terms and Conditions</h2>
              <p>
                {" "}
                By accessing and using our Internet based portal
                www.wegroup4.com (Website) users are consented to comply with
                these Terms of Use (Terms), Privacy Policy and Disclaimer. The
                usage of the Website also means that users have read and
                understood these Terms, Privacy Policy and Disclaimer, and have
                agreed to be bound by them. Additionally, users expressly agree
                that they will not infringe intellectual property rights such as
                copyright or trademark.
              </p>
              <p>
                {" "}
                The terms, read together with the Privacy Policy, constitute a
                legal and binding agreement between user and Radipro
                Technologies and is enforceable under the laws of India. By
                accepting the Terms, the user represents and warrants that they
                are at least 18 years of age and have right, authority, and
                legal capacity to enter into the agreement.
              </p>
              <h4>A. General </h4>{" "}
              <p>
                www.wegroup4.comis an Internet based portal owned and operated
                by Radipro Technologies India Pvt. Ltd., a company incorporated
                under the laws of India, having its office at NK- 1/605,FF-2,
                Indirapuram, Ghaziabad, UP-201014, India. Use of the Website is
                offered to you conditioned on acceptance of all the terms,
                conditions and notices contained in these Terms, along with any
                amendments made by Radipro Technologies at its sole discretion
                and posted on the Website. Radipro Technologies reserves the
                right to change the information, material and content provided
                on the Website without prior notice. Radipro Technologies holds
                the right to add, modify, change or vary all or any these Terms
                without prior notice. Website visitors are required to be aware
                of the law of land pertaining to any of the matters mentioned on
                the website. If the user decides to access the Website, it would
                be deemed as an action based on their own initiative and they
                would be held responsible for compliance with applicable local,
                national and international laws as the case may be. “Organiser”
                shall mean the person who shall initiates to co-ordinate and
                facilitates the process of Group gifting through Radipro
                Technologies. “Participant/s” shall mean person/s who shall
                contribute funds/resources towards purchase of gifts for the
                selected recipient of the group gifting. “Recipient” shall mean
                a person/entity who shall be receiving the gift from the
                Organiser and Participant/s. “User” shall mean a person/s
                including Organiser, Participant/s, Recipient or any other
                browser who is using or exploring our website or has shared
                his/her data/details on our website.
              </p>
              <h4>B. Communication </h4>{" "}
              <p>
                Radipro Technologies may use the information submitted by you to
                respond to any queries raised by you pursuant to your use and
                access to the Website. We may also use this information to send
                you e-mails and SMS for administrative and marketing purposes,
                through its servers and systems; or through third party e-mail
                or SMS service providers. User can unsubscribe/opt-out from our
                communications sent on SMS and email anytime:
              </p>
              <ol className="roman_list_type">
                <li>
                  Please email us at info@wegroup4.com to unsubscribe from SMS
                  or E-mail;
                </li>
                <li>
                  User can also un-subscribe from emails, by clicking the
                  "Unsubscribe URL" mentioned in the footer of the emails.
                </li>
              </ol>
              <h4>C. Functionalities Offered </h4>{" "}
              <p>
                This Website is an information gateway to our business model
                including the products and services that we extend to the Users.
              </p>
              <h4> D. Privacy Policy </h4>{" "}
              <p>
                You hereby understand and agree that you have read and fully
                understood the Privacy Policy of Radipro Technologies set out
                herein:{" "}
                <a href="https://www.wegroup4.com/privacy" target="blank">
                  https://www.wegroup4.com/privacy.
                </a>
              </p>
              <h4>E. User Obligations </h4>{" "}
              <p>
                You agree and undertake to use the Website only to access
                information regarding Radipro Technologies including the
                products and services provided by us. By way of example, and not
                as a limitation, you agree and undertake that when using the
                Website, you will not:
              </p>
              <ol>
                <li>
                  Modify, alter or otherwise make any derivative uses of our
                  content, trademarks, technology platform or its underlying
                  software, or any portion thereof;
                </li>
                <li>
                  Reverse engineer, modify, copy, distribute, transmit, display,
                  perform, reproduce, publish, license, create derivative works
                  from, transfer, or sell any information or software obtained
                  from the Website;
                </li>
                <li>
                  Infringe upon or violate the rights of Radipro Technologies,
                  its users or third parties;
                </li>
                <li>
                  Download, copy or imitate any portion of the Radipro
                  Technologies technology platform, its underlying software and
                  content, or its user interface, or the Website, except as
                  expressly permitted by us;
                </li>
                <li>
                  Aggregate or scrape any content, data or other information
                  from the Website to be aggregated or shown with material from
                  other sites or on a secondary site without our express written
                  permission;
                </li>
                <li>
                  Hyperlink to the Website or deep-link to any portion of our
                  Website without our written consent;
                </li>
                <li>
                  Interfere with our Website's attempt to access or steal any
                  data and information on or related to the Website;
                </li>
                <li>
                  Seek to transact any fraudulent or illegal activity, through
                  any means or corrupt practices, including but not limited to
                  any of the following: hacking, password mining, deceptive
                  impersonation of another person, misrepresentation of your
                  affiliation with a person or entity, hiding or attempting to
                  hide your true identity or location (including via proxy
                  server or otherwise) or providing false, inaccurate or altered
                  documentation, information or identification;
                </li>
                <li>
                  Circumvent or seek to circumvent any security measures or
                  other features meant to protect the security of the Website
                  and the users’ security;
                </li>
                <li>
                  Use the Website other than for its intended purposes
                  (including for any malicious, threatening, unethical,
                  harassing, fraudulent or illegal activity) or otherwise in
                  violation of these Terms of Use.
                </li>
                <li>
                  Upload files that contain software or other material protected
                  by intellectual property laws unless you own or control the
                  rights thereto or have received all necessary consents;
                </li>
                <li>
                  Upload or distribute files that contain viruses, corrupted
                  files, or any other similar software or programs that may
                  damage the operation of the Website or another's computer;
                </li>
                <li>
                  Conduct or forward surveys, contests, pyramid schemes or chain
                  letters;
                </li>
                <li>
                  Falsify or delete any author attributions, legal or other
                  proper notices or proprietary designations or labels of the
                  origin or source of software or other material contained in a
                  file that is uploaded;
                </li>
                <li>
                  Violate any code of conduct or other guidelines, which may be
                  applicable for or to any particular Service;
                </li>
                <li>
                  Violate any applicable laws or regulations for the time being
                  in force in or outside India;
                </li>
                <li>
                  Violate any provisions of these Terms of Use or any other
                  terms and conditions for the use of the Website contained
                  elsewhere herein; Under no circumstance shall Radipro
                  Technologies be liable for any consequential, indirect or
                  remote loss that you or your friends and family may suffer.
                </li>
              </ol>
              <h4>F. Usage Conduct </h4>
              <ol>
                <li>
                  You shall solely be responsible for maintaining the necessary
                  computer equipment and Internet connections that may be
                  required to access, use and transact on the Website;
                </li>
                <li>
                  You are also under an obligation to use this Website for
                  reasonable and lawful purposes only, and shall not indulge in
                  any activity that is not envisaged through the Website; and
                </li>
                <li>
                  You shall use this Website for personal, non-commercial use
                  only.
                </li>
              </ol>
              <h4>G. Intellectual Property Rights</h4>
              <ol>
                <li>
                  All the content on the website is protected by copyright.
                  Certain content on the Website may be protected as trademarks,
                  trade secrets or other intellectual property or proprietary
                  rights. Users shall abide by all the copyright notices,
                  trademark rules and restrictions contained on the website.
                  Radipro Technologies owns all Intellectual Property Rights to
                  and into the trademarks "Radipro Technologies", [MDT3] and the
                  Website, including, without limitation, any and all rights,
                  title and interest in and to copyright, related rights,
                  patents, utility models, designs, know-how, trade secrets and
                  inventions (patent pending), goodwill, source code, meta tags,
                  databases, text, content, graphics, icons, and hyperlinks. You
                  acknowledge and agree that you shall not use, reproduce or
                  distribute any content from the Website belonging to Radipro
                  Technologies without obtaining authorization from Radipro
                  Technologies.
                </li>
                <li>
                  Notwithstanding the foregoing, it is expressly clarified that
                  you will solely be responsible for any content that you
                  provide or upload when using any Service, including any text,
                  data, information, images, photographs, music, sound, video or
                  any other material which may be accessible through your post,
                  or any other content that you upload, transmit or store when
                  using the Website.
                </li>
                <li>
                  You may not modify, publish, transmit, participate in the
                  transfer or sale of, reproduce, create derivative works based
                  on, distribute, perform, display, or in any way exploit, any
                  of the content, idea available on the website, in whole or in
                  part for personal or commercial use.
                </li>
                <li>
                  By accessing the Website, the user agree that they will not
                  scrape or attempt to scrape or extract any data. The user also
                  agrees that they shall not use the Website for any monitoring,
                  benchmarking or throttling or any other purposes that may
                  impair the performance or functionality of the website.
                </li>
                <li>
                  The user agrees not to access or attempt to access any content
                  available on the website by any means other than through the
                  interface that is provided by Radipro Technologies.
                </li>
              </ol>
              <h4>H. Gift Card Storage Services </h4>
              <p>
                {" "}
                Radipro Technologies “Gift Card Storage Services” are provided
                to offer convenience to its users towards storing all the
                various Gift Card details at one place and no fees is levied on
                the user for such services. While Radipro Technologies has a
                robust security system in place. However,, you agree that the
                risk of any loss of information stored by you on the website
                will be borne by you and that Radipro Technologies shall not be
                liable for the same. Although Radipro Technologies agrees to
                take best efforts to protect security of the data provided,
                Radipro Technologies shall not be liable at all for any loss
                arising due to: (i) computer virus; (ii) third party hacking;
                (iii) technical glitches; or (iv) unauthorised access or use of
                information.
              </p>
              <h4> I. Group Gifting </h4>{" "}
              <p>
                {" "}
                The Organiser shall initiate the event for group gifting for any
                particular Recipient and he/she shall also add Participants whom
                the Organiser intends to make contributions for the Group
                Gifting event. The Participants shall have the choice to either
                accept the invite or to reject the same. In the event the
                Participant rejects the invite his journey in the Group Gifting
                event shall end and the remaining participants shall contribute
                in the same. Participants by virtue of joining Group Gifting
                Event for the recipient will construe that they are in the
                agreement with the gift selections made by the organiser for the
                recipient/s and the individual contribution amount arrived at
                for the same and thus agree to transfer all the authority/ power
                of attorney on their behalf in the hands of organiser for their
                respective contribution amount. And any action taken by the
                organiser shall be construed as action taken by each individual
                participant of the group. Any action taken by organiser of the
                group gifting event shall be construed that same has already
                been discussed and agreed with the contributing participants and
                thus any action taken by the organiser on behalf of participants
                shall be his personal responsibility towards the participants
                and recipient, and weGroup will consider the action taken by
                organiser on the web app has acceptance of the participants and
                shall facilitate the same. Any liability arising out of
                organisers action will be sole responsibility of the organiser
                towards its participants and the weGroup web app. we Group/
                Radipro Technologies / Company shall bring in changes in the app
                and notify all its users from time to time in order to enhance
                the collaboration and contribution experience among organisers ,
                participants and recipient/s towards achievement of successful
                group gifting execution.
              </p>
              <h4> J. Links to Third Party Sites </h4>{" "}
              <p>
                {" "}
                Radipro Technologies will contain links to other websites
                ("Linked Sites"). The Linked Sites are not under the control of
                Radipro Technologies, and Radipro Technologies is not
                responsible for the contents of any Linked Site, including
                without limitation any link contained in a Linked Site, or any
                changes or updates to a Linked Site. Radipro Technologies is not
                responsible for any form of transmission, whatsoever, received
                by you from any Linked Site. Radipro Technologies provides links
                of online advertisements to you only as a convenience and the
                inclusion of any link does not imply endorsement by or
                affiliation with Radipro Technologies of the Linked Sites nor
                does it represent the advice, views, opinions or beliefs of
                Radipro Technologies. The users are requested to verify the
                accuracy of all information on their own before undertaking any
                reliance on such information. In the event that by accessing the
                Website or following links to third-party websites you are
                exposed to content that you consider offensive or inappropriate,
                your only recourse will be to stop using the Website.
              </p>
              <h4> K. Disclaimer of Warranties & Liability </h4>{" "}
              <p>
                Radipro Technologies has endeavoured to ensure that all the
                information on the Website is correct, but Radipro Technologies
                neither warrants nor makes any representations regarding the
                quality, accuracy or completeness of any data, information,
                product or service. In no event shall Radipro Technologies be
                liable for any direct, indirect, punitive, incidental, special,
                consequential damages or any other damages. Neither shall
                Radipro Technologies be responsible for the delay or inability
                to use the Website or related Functionalities, the provision of
                or failure to provide Functionalities, or for any information,
                software, products, Functionalities and related graphics
                obtained through the Website, or otherwise arising out of the
                use of the website, whether based on contract, tort, negligence,
                strict liability or otherwise. Further, Radipro Technologies
                shall not be held responsible for non-availability of the
                Website during periodic maintenance operations or any unplanned
                suspension of access to the website that may occur due to
                technical reasons or for any reason beyond Radipro Technologies
                control. The user understands and agrees that any material
                and/or data downloaded or otherwise obtained through the Website
                is done entirely at their own discretion and risk and they will
                be solely responsible for any damage to their computer systems
                or loss of data that results from the download of such material
                and/or data. Further, members, affiliates, employees and
                authorised representatives for Radipro Technologies offer the
                website and the information on “as is" basis. They do not make
                any implied warranties, representations, endorsements or
                conditions with respect to the website or the gift
                cards/products made available on the website. Radipro
                Technologies does not warrant the availability of Website or
                addressal of the user requirements, or uninterrupted access.
                Similarly, it does not warrant that there will be no delays,
                failures, errors or omissions or loss of transmitted
                information, or transmission of viruses to the User’s mobile
                phone, computer or any other electronic devise. The
                responsibility for adequate protection and data back up solely
                lies on the user.
              </p>
              <h4> L. Indemnification </h4>{" "}
              <p>
                You agree to indemnify, defend and hold harmless Radipro
                Technologies from and against any and all losses, liabilities,
                claims, damages, costs and expenses (including legal fees and
                disbursements in connection therewith and interest chargeable
                thereon) asserted against or incurred by Radipro Technologies
                that arise out of, result from, or may be payable by virtue of,
                any breach or non- performance of any representation, warranty,
                covenant or agreement made or obligation to be performed by you
                pursuant to these Terms of Use.
              </p>
              <h4> M. Termination </h4>{" "}
              <p>
                {" "}
                Radipro Technologies may suspend or terminate your use by
                blocking your IP address from accessing the Website or any
                Service at its sole and absolute discretion without prior notice
                in case the person is found (i) to breach or deviate from the
                terms of this agreement (ii) On request of any law enforcement
                or government agency (iii) in case of any unexpected technical
                issues or problem that occurs. (i) Notwithstanding the foregoing
                sub-section above, these Terms will survive indefinitely unless
                and until Radipro Technologies chooses to terminate them; and
                (ii) If you or Radipro Technologies terminates your use of the
                Website, Radipro Technologies may delete any content or other
                materials relating to your use of the Website and Radipro
                Technologies will have no liability to you or any third party
                for doing so.
              </p>
              <h4> N. Governing Law </h4>
              <p>
                These terms shall be governed by and constructed in accordance
                with the laws of India without reference to conflict of laws
                principles, and disputes arising in relation hereto shall be
                subject to the exclusive jurisdiction of the courts at New
                Delhi, India.
              </p>
              <h4> O. Report Abuse </h4>{" "}
              <p>
                If you come across any abuse or violation of these Terms, please
                report to info@wegroup4.com
              </p>
              <h4> P. Revisions in the Terms </h4>{" "}
              <p>
                The Terms are subject to revision at any time by Radipro
                Technologies, and all the changes are effective immediately upon
                being posted on the Website. It is user’s responsibility to
                review these Terms periodically for updates/changes. Any use of
                the website after such notice will be deemed an acceptance of
                these changes by you.
              </p>
              <h4> Q. PRIVACY POLICY OBJECTIVE </h4>{" "}
              <p>
                Privacy Policy to be uploaded onto the websites of Radipro
                Technologies India Pvt Ltd, in order to ensure compliance with
                the Information Technology Act, 2000 and the Information
                Technology (Reasonable Security Practices and Procedures and
                Sensitive Personal Data or Information) Rules, 2011. Please find
                details of our Privacy Policy here -
                https://www.wegroup4.com/privacy
              </p>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="loginform">
            <h4 className="form-title">Login / Signup</h4>
            {otpScreen ? (
              <Form>
                <Form.Group className="formgroup" controlId="formBasicEmail">
                  <Form.Label>Enter OTP</Form.Label>
                  {browser && browser.os.toString().toLowerCase() == "ios" ? (
                    browser.name.toString().toLowerCase() == "ios" ? (
                      <>
                        <div className="otpinputs">
                          <OtpInput
                            className={inCorrect ? "otpbar error" : "otpbar"}
                            placeholder="000000"
                            value={otp}
                            isInputNum={true}
                            onChange={setOtp}
                            numInputs={6}
                            separator={<span>-</span>}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="fullotpbar">
                          <Form.Control
                            type="number"
                            max={6}
                            onChange={(e) => setOtp(e?.target?.value)}
                          />
                        </div>
                      </>
                    )
                  ) : (
                    <>
                      <div className="fullotpbar">
                        <Form.Control
                          type="number"
                          max={6}
                          onChange={(e) => setOtp(e?.target?.value)}
                        />
                      </div>
                    </>
                  )}
                  {inCorrect ? (
                    <p className="error-line">Incorrect OTP.</p>
                  ) : (
                    ""
                  )}
                  <div className="terms-bar">
                    <Form.Check
                      type="checkbox"
                      className="checkbox"
                      onChange={(e) => setTerms(!Terms)}
                    />
                    <div className="Terms-text">
                      <p>I agree to all the Terms & Conditions</p>
                      <button
                        class="simplebtnlink"
                        onClick={(e) => viewTermsPage()}
                      >
                        Read the terms & conditions
                      </button>
                    </div>
                  </div>
                </Form.Group>
                <div className="started-btn">
                  {loadingOtp ? (
                    <button className="btn-pink" onClick={() => {}}>
                      Loading....
                    </button>
                  ) : (
                    <button
                      className="btn-pink"
                      onClick={(e) => {
                        e.preventDefault();
                        submitOtp();
                      }}
                    >
                      Submit
                    </button>
                  )}
                </div>
                <div className="resend-otp">
                  {seconds > 0 ? (
                    <Button type="button" className="btngray timerbtn">
                      <span className="timer">
                        Resend OTP (
                        {seconds < 10 ? `00:0${seconds}` : `00:${seconds}`})
                      </span>
                    </Button>
                  ) : times > 0 ? (
                    <Button
                      type="button"
                      className="btngray"
                      onClick={resendOtp}
                    >
                      Resend OTP {`( ${times} Left )`}
                    </Button>
                  ) : (
                    <Button type="button" className="btngray timerbtn">
                      <span className="timer">Resend OTP</span>
                    </Button>
                  )}
                </div>
                <div className="form-divider">
                  <span>OR</span>
                </div>
                <button type="button" className="gbtn" onClick={GoogleLogin}>
                  <img src="/images/google.png" alt="logo" />
                  Sign up with Google
                </button>
              </Form>
            ) : (
              <>
                {" "}
                <Form>
                  <Form.Group className="formgroup" controlId="formBasicEmail">
                    <Form.Label>Mobile Number</Form.Label>
                    <div className="position-relative">
                      <PhoneInput
                        className={isError ? "error" : ""}
                        country={countryCode}
                        value={phoneNumber}
                        onChange={(phoneNumber) => setPhoneNumber(phoneNumber)}
                        placeholder="Enter your mobile number"
                        onKeyPress={(event) => {
                          if (event.key === "Enter") {
                            submitHandler();
                          }
                        }}
                        enableLongNumbers={true}
                        countryCodeEditable={false}
                      />

                      {isphoneEmpty ? (
                        <p className="error-line">
                          Please enter your mobile number.
                        </p>
                      ) : (
                        ""
                      )}
                      {isError ? (
                        <p className="error-line">
                          Failed to send OTP. Please check your mobile number.
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </Form.Group>
                  <div className="started-btn">
                    {!loading ? (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          submitHandler();
                        }}
                        className="btn-pink"
                      >
                        Submit
                      </button>
                    ) : (
                      <span className="btn-pink">Loading....</span>
                    )}
                  </div>
                  <div className="form-divider">
                    <span>OR</span>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="gbtn"
                      onClick={GoogleLogin}
                    >
                      <img src="/images/google.png" alt="logo" />
                      Sign up with Google
                    </button>
                  </div>
                </Form>
              </>
            )}
            <div id="hellllll" ref={(ref) => (recaptchaWrapperRef = ref)}>
              <div id="recaptcha-container"></div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Login;
