import React, { useState, useContext, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { AuthContext } from "../../Context/AuthContext";
import AuthAction from "../../Actions/auth.service";
import {
  haveValue,
  isValidDate,
  validateJOIFormField,
  validateJOIProperty,
} from "./../../utils/helpers";
import * as Joi from "joi-browser";
import FieldError from "./../../../src/Components/Common/FieldError";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import axios from "axios";
import { ShowImage } from "./../../utils/helpers";
import { showLoader, hideLoader } from "./../../utils/helpers";
import { ThemeContext } from "../../hooks/useThemeContext";
import moment from "moment";
import { toast } from "react-toastify";
import imageCompression from "browser-image-compression";
import { auth } from "../../firbase";
import {
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  sendEmailVerification,
  signInWithEmailAndPassword,
} from "firebase/auth";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

const EditProfile = () => {
  const navigate = useNavigate();
  const {
    isLoggedIn,
    setIsLoggedIn,
    setIsSidebarVisible,
    setLoggedInUserData,
  } = useContext(AuthContext);
  const [myUserInfo, setMyUserInfo] = useState([]);
  const [currentUserInfo, setCurrentUserInfo] = useState([]);
  const [errors, setErrors] = useState({});
  const [countryCode, setCountryCode] = useState("us");
  const [imageUpdate, setImageUpdate] = useState();
  const [startDate, setStartDate] = useState(new Date());

  const { showErrorToast, showSuccessToast } = useContext(ThemeContext);

  const schema = {
    fullName: Joi.string().required().label("Name"),
    email: Joi.string().email().required().label("Email"),
    phoneNumber: Joi.string().required().label("Phone Number"),
    dob: Joi.string().allow("").label("Date Of Birth"),
    anniversary: Joi.string().allow("").label("Anniversary"),
  };

  const getLocation = async () => {
    if (!haveValue(myUserInfo?.phoneNumber)) {
      axios({ url: `https://ipapi.co/json/` })
        .then((res) => {
          setCountryCode(res?.data?.country_code.toString().toLowerCase());
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      showLoader();
      AuthAction.getUserData()
        .then((res) => {
          if (!haveValue(res?.phoneNumber)) getLocation();
          setMyUserInfo(res);
          setCurrentUserInfo(res);
          hideLoader();
        })
        .catch((err) => {
          console.log(err);
          hideLoader();
          if (err?.response?.data?.message == "Unauthorized User.") {
            localStorage.removeItem("token");
            setIsLoggedIn(false);
            setIsSidebarVisible(false);

            navigate("/");
          }
        });
    }

    return () => {
      console.log(myUserInfo?.phoneNumber);
      // getLocation();
    };
  }, []);

  const handleForm = async (e, name) => {
    let fieldName = name;
    let fieldValue = e;
    let errorData = { ...errors };
    console.log(fieldValue, "fieldValuefieldValue");
    const errorMessage = validateJOIProperty(schema, fieldName, fieldValue);
    if (errorMessage) {
      errorData[fieldName] = errorMessage;
    } else {
      delete errorData[fieldName];
    }
    setErrors(errorData);
    if (e instanceof File && e.type.startsWith("image/")) {
      const imageFile = e;
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
      };
      try {
        showLoader();
        const compressedFile = await imageCompression(imageFile, options);
        var convertBlobToFile = await new File(
          [compressedFile],
          compressedFile.name,
          { type: "image/jpeg" }
        );
        fieldValue = convertBlobToFile;
        setMyUserInfo((existingValue) => ({
          ...existingValue,
          [fieldName]: fieldValue,
        }));
        hideLoader();
      } catch (error) {
        console.log(error);
      }
    } else {
      setMyUserInfo((existingValue) => ({
        ...existingValue,
        [fieldName]: fieldValue,
      }));
    }

    if (name === "file") {
      setImageUpdate(fieldValue);
    }
  };

  const verifyEmail = async () => {
    createUserWithEmailAndPassword(auth, myUserInfo?.email, "password")
      .then((userCredential) => {
        // send verification mail.
        sendEmailVerification(auth.currentUser)
          .then((res) => {
            // Email verification sent!
            auth.signOut();
          })
          .catch(alert);
      })
      .catch((err) => {
        console.log({ err }, "err");
      });
  };

  const resendEmail = async () => {
    signInWithEmailAndPassword(auth, myUserInfo?.email, "password").then(
      (userCredential) => {
        sendEmailVerification(auth.currentUser)
          .then((res) => {
            auth.signOut();
          })
          .catch(alert);
        auth.signOut();
      }
    );
  };

  const updateDetails = () => {
    let errors = validateJOIFormField(myUserInfo, schema);
    if (errors == null) {
      // return false;
      const data = new FormData();
      data.append("fullName", myUserInfo.fullName);
      data.append("email", myUserInfo.email);
      if (haveValue(myUserInfo.phoneNumber)) {
        if (myUserInfo.phoneNumber.startsWith("+")) {
          data.append("phoneNumber", myUserInfo.phoneNumber);
        } else {
          data.append("phoneNumber", "+" + myUserInfo.phoneNumber);
        }
      }
      // if (haveValue(myUserInfo.dob)) {
      data.append("dob", myUserInfo.dob);
      // }
      data.append("anniversary", myUserInfo.anniversary);
      // data.append("file",)
      if (myUserInfo.file) {
        data.append("file", myUserInfo.file);
      }
      showLoader();
      AuthAction.updateProfile(data)
        .then((res) => {
          if (
            currentUserInfo?.email != myUserInfo?.email &&
            haveValue(myUserInfo?.email)
          ) {
            fetchSignInMethodsForEmail(auth, myUserInfo?.email)
              .then(function (signInMethods) {
                if (signInMethods.length > 0) {
                  console.log("User exists");
                  resendEmail();
                } else {
                  console.log("User does not exist");
                  verifyEmail();
                }
              })
              .catch(function (error) {
                console.log("Error checking user:", error);
              });
            showSuccessToast("Email Verification Mail Sent.");
          } else {
            showSuccessToast("User Details Updated");
          }
          hideLoader();
          setLoggedInUserData(res);
          // console.log("ADTER AKLSDJALSD",res)
          setTimeout(() => {
            navigate("/getStarted");
          }, 3000);
        })
        .catch((err) => {
          hideLoader();
          console.log(err);
          showErrorToast(err?.response?.data?.message);
          if (err?.response?.data?.message == "Unauthorized User.") {
            localStorage.removeItem("token");
            toast.dismiss();
            setIsLoggedIn(false);
            setIsSidebarVisible(false);

            navigate("/");
          }
          console.log(err);
        });
    } else {
      setErrors(errors);
    }
  };
  console.log(myUserInfo);

  return (
    <>
      <div className="profile-tophead">
        <h4 className="titleevent">Edit Profile</h4>
        <div className="uploadmedia imageuploaded">
          {imageUpdate ? (
            <>
              {" "}
              <div className="avatar-img">
                <div className="avatar-img d-flex">
                  <img src={URL.createObjectURL(imageUpdate)} alt="img" />
                  <label className="bg-transparent border-0 p-0 m-0 camera_svg">
                    <input
                      type="file"
                      accept="image/*"
                      className="uploadinput d-none"
                      onChange={(e) => handleForm(e.target.files[0], "file")}
                    />
                    <svg
                      className="cursor-pointer"
                      width="35"
                      height="35"
                      viewBox="0 0 64 64"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="32" cy="32" r="32" fill="#E31B53" />
                      <path
                        d="M36.1654 32.8332C36.1654 33.9382 35.7264 34.998 34.945 35.7794C34.1636 36.5609 33.1038 36.9998 31.9987 36.9998C30.8936 36.9998 29.8338 36.5609 29.0524 35.7794C28.271 34.998 27.832 33.9382 27.832 32.8332C27.832 31.7281 28.271 30.6683 29.0524 29.8869C29.8338 29.1055 30.8936 28.6665 31.9987 28.6665C33.1038 28.6665 34.1636 29.1055 34.945 29.8869C35.7264 30.6683 36.1654 31.7281 36.1654 32.8332Z"
                        fill="white"
                      />
                      <path
                        d="M21.9993 25.3333C21.1153 25.3333 20.2674 25.6845 19.6423 26.3096C19.0172 26.9348 18.666 27.7826 18.666 28.6667V38.6667C18.666 39.5507 19.0172 40.3986 19.6423 41.0237C20.2674 41.6488 21.1153 42 21.9993 42H41.9994C42.8834 42 43.7313 41.6488 44.3564 41.0237C44.9815 40.3986 45.3327 39.5507 45.3327 38.6667V28.6667C45.3327 27.7826 44.9815 26.9348 44.3564 26.3096C43.7313 25.6845 42.8834 25.3333 41.9994 25.3333H40.046C39.162 25.3331 38.3143 24.9818 37.6893 24.3567L36.3094 22.9767C35.6844 22.3515 34.8367 22.0002 33.9527 22H30.046C29.162 22.0002 28.3143 22.3515 27.6893 22.9767L26.3093 24.3567C25.6844 24.9818 24.8367 25.3331 23.9527 25.3333H21.9993ZM22.8327 28.6667C22.6117 28.6667 22.3997 28.5789 22.2434 28.4226C22.0871 28.2663 21.9993 28.0543 21.9993 27.8333C21.9993 27.6123 22.0871 27.4004 22.2434 27.2441C22.3997 27.0878 22.6117 27 22.8327 27C23.0537 27 23.2657 27.0878 23.4219 27.2441C23.5782 27.4004 23.666 27.6123 23.666 27.8333C23.666 28.0543 23.5782 28.2663 23.4219 28.4226C23.2657 28.5789 23.0537 28.6667 22.8327 28.6667ZM37.8327 32.8333C37.8327 34.3804 37.2181 35.8642 36.1241 36.9581C35.0302 38.0521 33.5464 38.6667 31.9993 38.6667C30.4523 38.6667 28.9685 38.0521 27.8746 36.9581C26.7806 35.8642 26.166 34.3804 26.166 32.8333C26.166 31.2862 26.7806 29.8025 27.8746 28.7085C28.9685 27.6146 30.4523 27 31.9993 27C33.5464 27 35.0302 27.6146 36.1241 28.7085C37.2181 29.8025 37.8327 31.2862 37.8327 32.8333Z"
                        fill="white"
                      />
                    </svg>
                  </label>
                </div>
              </div>
            </>
          ) : (
            <>
              {" "}
              <div className="avatar-img">
                <div className="avatar-img d-flex">
                  <img
                    src={ShowImage(
                      myUserInfo?.profilePhoto,
                      myUserInfo?.fullName
                    )}
                    alt="img"
                  />
                  <label className="bg-transparent border-0 p-0 m-0 camera_svg">
                    <input
                      type="file"
                      accept="image/*"
                      className="uploadinput d-none"
                      onChange={(e) => handleForm(e.target.files[0], "file")}
                    />
                    <svg
                      className="cursor-pointer"
                      width="35"
                      height="35"
                      viewBox="0 0 64 64"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="32" cy="32" r="32" fill="#E31B53" />
                      <path
                        d="M36.1654 32.8332C36.1654 33.9382 35.7264 34.998 34.945 35.7794C34.1636 36.5609 33.1038 36.9998 31.9987 36.9998C30.8936 36.9998 29.8338 36.5609 29.0524 35.7794C28.271 34.998 27.832 33.9382 27.832 32.8332C27.832 31.7281 28.271 30.6683 29.0524 29.8869C29.8338 29.1055 30.8936 28.6665 31.9987 28.6665C33.1038 28.6665 34.1636 29.1055 34.945 29.8869C35.7264 30.6683 36.1654 31.7281 36.1654 32.8332Z"
                        fill="white"
                      />
                      <path
                        d="M21.9993 25.3333C21.1153 25.3333 20.2674 25.6845 19.6423 26.3096C19.0172 26.9348 18.666 27.7826 18.666 28.6667V38.6667C18.666 39.5507 19.0172 40.3986 19.6423 41.0237C20.2674 41.6488 21.1153 42 21.9993 42H41.9994C42.8834 42 43.7313 41.6488 44.3564 41.0237C44.9815 40.3986 45.3327 39.5507 45.3327 38.6667V28.6667C45.3327 27.7826 44.9815 26.9348 44.3564 26.3096C43.7313 25.6845 42.8834 25.3333 41.9994 25.3333H40.046C39.162 25.3331 38.3143 24.9818 37.6893 24.3567L36.3094 22.9767C35.6844 22.3515 34.8367 22.0002 33.9527 22H30.046C29.162 22.0002 28.3143 22.3515 27.6893 22.9767L26.3093 24.3567C25.6844 24.9818 24.8367 25.3331 23.9527 25.3333H21.9993ZM22.8327 28.6667C22.6117 28.6667 22.3997 28.5789 22.2434 28.4226C22.0871 28.2663 21.9993 28.0543 21.9993 27.8333C21.9993 27.6123 22.0871 27.4004 22.2434 27.2441C22.3997 27.0878 22.6117 27 22.8327 27C23.0537 27 23.2657 27.0878 23.4219 27.2441C23.5782 27.4004 23.666 27.6123 23.666 27.8333C23.666 28.0543 23.5782 28.2663 23.4219 28.4226C23.2657 28.5789 23.0537 28.6667 22.8327 28.6667ZM37.8327 32.8333C37.8327 34.3804 37.2181 35.8642 36.1241 36.9581C35.0302 38.0521 33.5464 38.6667 31.9993 38.6667C30.4523 38.6667 28.9685 38.0521 27.8746 36.9581C26.7806 35.8642 26.166 34.3804 26.166 32.8333C26.166 31.2862 26.7806 29.8025 27.8746 28.7085C28.9685 27.6146 30.4523 27 31.9993 27C33.5464 27 35.0302 27.6146 36.1241 28.7085C37.2181 29.8025 37.8327 31.2862 37.8327 32.8333Z"
                        fill="white"
                      />
                    </svg>
                  </label>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="userinfo-bar">
          <h4>{myUserInfo?.fullName}</h4>
        </div>
        <Form>
          <Form.Group className="formgroup" controlId="formBasicEmail">
            <Form.Label>Name</Form.Label>
            <Form.Control
              name="fullName"
              type="text"
              placeholder="Enter your name"
              onChange={(e) => {
                const regex = /^[a-zA-Z0-9 ]*$/;
                const inputValue = e.target.value;
                if (regex.test(inputValue)) {
                  handleForm(e?.target.value, "fullName");
                }
              }}
              value={myUserInfo?.fullName}
            />
            <FieldError error={errors.fullName} />
          </Form.Group>
          <Form.Group className="formgroup" controlId="formBasicEmail">
            <Form.Label>Email ID</Form.Label>
            <Form.Control
              name="email"
              type="email"
              placeholder="Enter your email"
              value={myUserInfo?.email}
              onChange={(e) => handleForm(e?.target.value, "email")}
            />
            <FieldError error={errors.email} />
          </Form.Group>
          <Form.Group className="formgroup" controlId="formBasicEmail">
            <Form.Label>Mobile Number</Form.Label>
            <PhoneInput
              className={errors.phoneNumber ? "error" : ""}
              country={countryCode}
              onChange={(e) => handleForm(e, "phoneNumber")}
              value={myUserInfo?.phoneNumber}
              placeholder="Enter your mobile number"
              enableLongNumbers={true}
              countryCodeEditable={false}
            />
            <FieldError error={errors.phoneNumber} />
          </Form.Group>
          <Form.Group className="formgroup w-100" controlId="formBasicEmail">
            <Form.Label>Date of Birth</Form.Label>
            {/* <input className='datepickerbar' type='date' placeholder="Date" onChange={(e) => handleForm(e.target.value, "dob")} max={moment().format("YYYY-MM-DD")} value={myUserInfo?.dob} /> */}
            <div className="d-flex gap-4 align-items-center">
              <DatePicker
                selected={
                  haveValue(myUserInfo?.dob) ? new Date(myUserInfo?.dob) : ""
                }
                //   onSelect={(date) => setStartDate(date)}
                onChange={(date) => {
                  handleForm(moment(date).format("YYYY-MM-DD"), "dob");
                }}
                value={new Date(myUserInfo?.dob)}
                className="datepickerbar"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                maxDate={new Date()}
              />
              {/* <img
                onClick={(e) => {
                  setMyUserInfo((existingValue) => ({
                    ...existingValue,
                    ["dob"]: "",
                  }));
                }}
                className="trash-icon"
                src="/images/trash.png"
              /> */}
            </div>
          </Form.Group>
          <Form.Group className="formgroup w-100" controlId="formBasicEmail">
            <Form.Label>Anniversary.</Form.Label>
            {/* <DatePicker value={myUserInfo?.anniversary} onChange={(date) => handleForm(date, 'anniversary')} /> */}
            <div className="d-flex gap-4 align-items-center">
              <DatePicker
                selected={
                  haveValue(myUserInfo?.anniversary)
                    ? new Date(myUserInfo?.anniversary)
                    : ""
                }
                //   onSelect={(date) => setStartDate(date)}
                onChange={(date) => {
                  handleForm(moment(date).format("YYYY-MM-DD"), "anniversary");
                }}
                value={new Date(myUserInfo?.anniversary)}
                className="datepickerbar"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
              {/* <img
                onClick={(e) => {
                  setMyUserInfo((existingValue) => ({
                    ...existingValue,
                    ["anniversary"]: "",
                  }));
                }}
                className="trash-icon"
                src="/images/trash.png"
              /> */}
            </div>

            {/* <input className='datepickerbar' type='date' placeholder="Date" onChange={(e) => handleForm(e.target.value, "anniversary")} value={myUserInfo?.anniversary} /> */}
            <FieldError error={errors.anniversary} />
          </Form.Group>
        </Form>
      </div>
      <div className="eventbar mt-5">
        <Button className="btn-pink mt-3" onClick={updateDetails}>
          Update
        </Button>
      </div>
    </>
  );
};

export default EditProfile;
