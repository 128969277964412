import React, { useState, useEffect, useContext } from "react";
import Form from "react-bootstrap/Form";
import { BiPencil } from "react-icons/bi";
import "react-calendar/dist/Calendar.css";
import PhoneInput from "react-phone-input-2";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import * as Joi from "joi-browser";
import {
  haveValue,
  hideLoader,
  showLoader,
  validateJOIFormField,
  validateJOIProperty,
} from "./../../utils/helpers";
import FieldError from "./../../../src/Components/Common/FieldError";
import Modal from "react-bootstrap/Modal";
import "react-phone-input-2/lib/style.css";
import UploadImage from "../../Components/UploadMedia/UploadImage";
import imageCompression from "browser-image-compression";
import { ThemeContext } from "../../hooks/useThemeContext";
import EventHeaderTitle from "./EventHeaderTitle";

const EventStep1 = (props) => {
  const navigate = useNavigate();
  const { setBackUrlContext } = useContext(ThemeContext);
  // State variables
  const { event, setEvent } = props;
  const [countryCode, setCountryCode] = useState("us");
  const [errors, setErrors] = useState({});
  const [checkFields, setCheckFields] = useState(true);
  const [alertShow, setAlertShow] = useState(false);
  const [compressedImage, setCompressedImage] = useState(null);

  const schema = {
    phone: Joi.string().required().label("Phone"),
    email: Joi.string().email().required().label("Email"),
  };

  const getLocation = async () => {
    if (!haveValue(event?.phone)) {
      axios({ url: `https://ipapi.co/json/` })
        .then((res) => {
          setCountryCode(res?.data?.country_code.toString().toLowerCase());
        })
        .catch((err) => {});
    }
  };
  useEffect(() => {
    getLocation();
    setBackUrlContext(`/eventstep1`);
  }, []);

  // useEffect(() => {
  //     checkFieldEmtpy();
  // }, [])

  // const checkFieldEmtpy = () => {
  //     if (!haveValue(event?.phone)) {
  //         // if (checkFields == true) {
  //         //     setCheckFields(false);
  //         // }
  //         // if (checkFields == false) {
  //         setCheckFields(false);
  //         // }
  //     }
  // }

  const handleForm = async (e, name) => {
    const fieldName = name;
    let fieldValue = e;

    if (name == "phone") {
      let temp_number_string = e.toString();
      // let lastTenDigits = temp_number_string.slice(-12);
      let lastTenDigits = temp_number_string;
      fieldValue = lastTenDigits;
    }

    if (e instanceof File && e.type.startsWith("image/")) {
      const imageFile = e;
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
      };
      try {
        showLoader();
        const compressedFile = await imageCompression(imageFile, options);
        var fileName = await new File([compressedFile], compressedFile.name, {
          type: "image/jpeg",
        });
        fieldValue = fileName;
        setEvent((existingValue) => ({
          ...existingValue,
          [fieldName]: fieldValue,
        }));
        hideLoader();
      } catch (error) {
        console.log(error);
      }
    } else {
      setEvent((existingValue) => ({
        ...existingValue,
        [fieldName]: fieldValue,
      }));
    }
  };

  const handleClosedateadded = () => {
    setAlertShow(false);
  };
  const eventstate3 = () => {
    showLoader();
    let errors = validateJOIFormField(event, schema);
    if (errors == null) {
      setAlertShow(true);
      hideLoader();
      // alert('Please re-check the recipient details as gift card will be sent to this email & phone number.');
    } else {
      setErrors(errors);
      hideLoader();
    }
  };

  return (
    <>
      <div className="event   bar">
        <EventHeaderTitle />

        <div className="rangebar">
          <img src="/images/step2.png" alt="step2" />
          <h4>Step 2 of 3</h4>
        </div>
        <Form>
          <Form.Group className="formgroup" controlId="formBasicEmail">
            <div className="Event-addbar">
              <div className="eventleft">
                <svg
                  width="20"
                  height="23"
                  viewBox="0 0 20 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 9.69019H1M19 11.1902V8.49019C19 6.81003 19 5.96995 18.673 5.32821C18.3854 4.76373 17.9265 4.30479 17.362 4.01717C16.7202 3.69019 15.8802 3.69019 14.2 3.69019H5.8C4.11984 3.69019 3.27976 3.69019 2.63803 4.01717C2.07354 4.30479 1.6146 4.76373 1.32698 5.32821C1 5.96995 1 6.81003 1 8.49019V16.8902C1 18.5703 1 19.4104 1.32698 20.0522C1.6146 20.6166 2.07354 21.0756 2.63803 21.3632C3.27976 21.6902 4.11984 21.6902 5.8 21.6902H10.5M14 1.69019V5.69019M6 1.69019V5.69019M15.4976 15.4021C14.7978 14.623 13.6309 14.4134 12.7541 15.1269C11.8774 15.8403 11.7539 17.0332 12.4425 17.877C13.131 18.7208 15.4976 20.6902 15.4976 20.6902C15.4976 20.6902 17.8642 18.7208 18.5527 17.877C19.2413 17.0332 19.1329 15.8328 18.2411 15.1269C17.3492 14.4209 16.1974 14.623 15.4976 15.4021Z"
                    stroke="#E31B54"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <div className="detail">
                  <h5>
                    <span className="name">
                      {event?.firstName} {event?.lastName}
                    </span>
                    's <span className="eventname">{event?.occasionId}</span>
                  </h5>
                  <h6 className="eventdate">
                    {event?.date} {event?.time ? `| ${event?.time}` : ""}
                  </h6>
                </div>
              </div>
              <Link className="editpage" to="/eventstep1">
                <BiPencil />
              </Link>
            </div>
          </Form.Group>
          <Form.Group className="formgroup" controlId="formBasicEmail">
            <Form.Label>
              {event?.firstName} {event?.lastName}’s Mobile Number
            </Form.Label>
            <PhoneInput
              enableLongNumbers={true}
              countryCodeEditable={false}
              className={errors.phone ? "error" : ""}
              country={countryCode}
              onChange={(e) => handleForm(e, "phone")}
              value={event?.phone}
              placeholder={`Enter ${event?.email}'s Number`}
            />
            <FieldError error={errors.phone} />
          </Form.Group>
          <Form.Group className="formgroup giftnote" controlId="formBasicEmail">
            <Form.Label>
              {event?.firstName} {event?.lastName} Email ID
            </Form.Label>
            <Form.Control
              className={errors.email ? "error" : ""}
              type="email"
              onChange={(e) => handleForm(e?.target.value, "email")}
              value={event?.email}
              placeholder={`@ Enter ${event?.firstName} ${event?.lastName}'s Email ID`}
            />
            <p className="note">** Digital gifts will be delivered here</p>
          </Form.Group>
          <FieldError error={errors.email} />
          <div className="groupgift-bluebar noflex">
            <h6>Don't Worry!</h6>
            <p>
              {event?.firstName} {event?.lastName} will not receive any message
              or email untill event day.
            </p>
          </div>
          <UploadImage
            errors={errors}
            icon={event?.icon}
            name={`${event?.firstName} ${event?.lastName}`}
            handleChangeIcon={(e) => handleForm(e, "icon")}
          />
          <Button onClick={eventstate3} className="btn-pink">
            Proceed
          </Button>
        </Form>
      </div>

      <Modal
        className="Createevent-modal fixamountpopup dateadded-popup"
        show={alertShow}
        onHide={handleClosedateadded}
      >
        <Modal.Header className="faviconadded" closeButton>
          <img src="./images/favicon.png" />
          weGroup
        </Modal.Header>
        <Modal.Body>
          Please re-check the recipient details as gift card will be sent to
          this email & phone number.
        </Modal.Body>
        <Modal.Footer>
          <Link type="button" to={"/eventstep3"} className="btn-pink">
            Ok
          </Link>
          <Button
            className="btngray"
            onClick={handleClosedateadded}
            type="button"
          >
            Modify
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EventStep1;
